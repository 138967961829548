import { defineStore, storeToRefs } from 'pinia'
import type { Card as ProductCard } from '@academica-box/components/Cards/UiProductCard/types'
import { usePromoStore } from '@academica-box/composables/store/promo'
import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
import { GetCoursesSortEnum } from '@skillbox/academica-box/src/swagger/academgen'
import type { AuthorsBlock, FaqBlock } from '@/api/mainPage'

export const useMainStore = defineStore('main', {
  state: () => ({
    coursesByVendor: [],
    coursesByDirection: [],
    freeCourses: [],
    coursesUnder1000: [],
    coursesByDate: [],
    authorsBlock: null,
    faqBlock: null,
    isLoading: false,
  } as {
    coursesByVendor: ProductCard[];
    coursesByDirection: ProductCard[];
    coursesUnder1000: ProductCard[];
    freeCourses: ProductCard[];
    coursesByDate: ProductCard[];
    authorsBlock: AuthorsBlock | null
    faqBlock: FaqBlock | null
    isLoading: boolean;
  }),

  actions: {
    async getCoursesByVendor(slug: string) {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      const response = await $api.courses.getCoursesByFilters({
        companies: [slug],
        limit: 4,
        sort: GetCoursesSortEnum.PriceAsc
      }, {
        isPromo: Boolean(promoStore.promo),
        isSubscriptionAvailable: isSubscriptionAvailable.value,
      })

      this.coursesByVendor = response.courses

      this.isLoading = false
    },

    async getFreeCourses() {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      const response = await $api.courses.getCoursesByFilters({
        priceMin: 0,
        priceMax: 0,
        durationMin: 15,
        durationMax: 15,
        limit: 4,
      }, {
        isPromo: Boolean(promoStore.promo),
        isSubscriptionAvailable: isSubscriptionAvailable.value,
      })

      this.freeCourses = response.courses

      this.isLoading = false
    },

    async getCoursesByDirection(direction: string) {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      const response = await $api.courses.getCoursesByFilters({
        direction,
        priceMin: 1,
        limit: 4,
        sort: GetCoursesSortEnum.PriceAsc
      }, {
        isPromo: Boolean(promoStore.promo),
        isSubscriptionAvailable: isSubscriptionAvailable.value,
      })

      this.coursesByDirection = response.courses

      this.isLoading = false
    },

    async getCoursesUnder1000() {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      const response = await $api.courses.getCoursesByFilters({
        priceMin: 1,
        priceMax: 990,
        limit: 4,
        sort: GetCoursesSortEnum.PriceAsc
      }, {
        isPromo: Boolean(promoStore.promo),
        isSubscriptionAvailable: isSubscriptionAvailable.value,
      })

      this.coursesUnder1000 = response.courses

      this.isLoading = false
    },

    async getCoursesByDate() {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      const response = await $api.courses.getCoursesByFilters({
        limit: 4,
        sort: GetCoursesSortEnum.Date
      }, {
        isPromo: Boolean(promoStore.promo),
        isSubscriptionAvailable: isSubscriptionAvailable.value,
      })

      this.coursesByDate = response.courses

      this.isLoading = false
    },

    async getMainPage() {
      const { $api, $sentry } = useNuxtApp()

      try {
        const result = await $api.mainPage.getMainPage()

        this.authorsBlock = result.authorsBlock
        this.faqBlock = result.faqBlock

        return result
      } catch (error) {
        $sentry.captureException(error)
      }
    },
  }
})
